import React from "react";
import ReactDOM from "react-dom";

const screenStates = {
    DEFAULT: 'default',
    SUBMITTING: 'submitting',
    SUCCESS: 'success',
    FAILURE: 'failure'
};

class Contact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            intialState: this.props.viewModel,
            viewModel: this.props.viewModel,
            screenstate: screenStates.DEFAULT
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;        
        this.setState({ viewModel: { ...this.state.viewModel, [name]: value } });
        $('.contact-buttons').hide();
        $('#contact-submit').show();
        console.log(this.state.viewModel);
    }

    handleSubmit(event) {
        event.preventDefault();

        var callingForm = this;
        if (this.state.viewModel.terms)
        {
            console.log(callingForm.state.viewModel);
            callingForm.setState({ state: screenStates.SUBMITTING });

            $('.contact-buttons').hide();
            $('#contact-submitting').show();

            fetch('/contact/initiate', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(callingForm.state.viewModel)
            })
                .then(function (response) {
                    if (response.status === 200) {
                        callingForm.setState({ screenstate: screenStates.SUCCESS });
                        $('.contact-buttons').hide();
                        $('#contact-success').show();
                        setTimeout(function () {
                            callingForm.setState({ screenstate: screenStates.DEFAULT, viewModel: callingForm.state.intialState });
                            $('.contact-buttons').hide();
                            $('#contact-submit').show();
                            { $('.search-popup').removeClass('open'); }
                            { $('.message-popup').removeClass('open'); }
                            { $('.popup-gallery').removeClass('open'); }
                        }, 3000);
                    }
                    else {
                        callingForm.setState({ screenstate: screenStates.FAILURE });
                        $('.contact-buttons').hide();
                        $('#contact-failure').show();
                        setTimeout(function () {
                            callingForm.setState({ screenstate: screenStates.DEFAULT, viewModel: callingForm.state.intialState });
                            $('.contact-buttons').hide();
                            $('#contact-submit').show();
                        }, 3000);
                    }
                })
                .catch(function () {
                    callingForm.setState({ state: screenStates.FAILURE });
                    $('.contact-buttons').hide();
                    $('#contact-failure').show();
                    setTimeout(function () {
                        callingForm.setState({ screenstate: screenStates.DEFAULT });
                        $('.contact-buttons').hide();
                        $('#contact-submit').show();
                    }, 3000);
                });
        }
        else
        {
            $('.contact-buttons').hide();
            $('#contact-validation').show();
            setTimeout(function () {
                callingForm.setState({ screenstate: screenStates.DEFAULT });
                $('.contact-buttons').hide();
                $('#contact-submit').show();
            }, 3000);
        }
    }

    render() {
        return (
            <div className="send-message-popup">
                <h5>Connect with us</h5>
                <p>We would love to work with you and help in any way possible. Please give us a few details and we'll get back to you soon.</p>
                <form className="contact-form" onSubmit={this.handleSubmit}>
                    <div className="with-icon">
                        <input name="location" placeholder="Where are you based?" type="text" required="required" value={this.state.viewModel.location} onChange={this.handleChange} />
                        <svg className="utouch-icon utouch-icon-icon-1">
                            <use xlinkHref="#utouch-icon-worlwide"/>
                        </svg>
                    </div>
                    <div className="with-icon">
                        <input name="name" placeholder="Your Name" type="text" required="required" value={this.state.viewModel.name} onChange={this.handleChange} />
                        <svg className="utouch-icon utouch-icon-user">
                            <use xlinkHref="#utouch-icon-user"/>
                        </svg>
                    </div>
                    <div className="with-icon">
                        <input name="email" placeholder="Email Address" type="email" required="required" value={this.state.viewModel.email} onChange={this.handleChange} />
                        <svg className="utouch-icon utouch-icon-message-closed-envelope-1">
                            <use xlinkHref="#utouch-icon-message-closed-envelope-1"/>
                        </svg>
                    </div>
                    <div className="with-icon">
                        <input className="with-icon" name="phoneNumber" placeholder="Phone Number (+ country code)" type="tel" required="required" value={this.state.viewModel.phoneNumber} onChange={this.handleChange} />
                        <svg className="utouch-icon utouch-icon-telephone-keypad-with-ten-keys">
                            <use xlinkHref="#utouch-icon-telephone-keypad-with-ten-keys"/>
                        </svg>
                    </div>
                    <div className="with-icon">
                        <input className="with-icon" name="company" placeholder="Company" type="text" required="required" value={this.state.viewModel.company} onChange={this.handleChange} />
                        <svg className="utouch-icon utouch-icon-icon-1">
                            <use xlinkHref="#utouch-icon-icon-1"/>
                        </svg>
                    </div>
                    <div className="with-icon">
                        <textarea name="description" placeholder="What can we help you?" style={{ height: '180px' }} required="required" value={this.state.viewModel.description} onChange={this.handleChange}/>
                        <svg className="utouch-icon utouch-icon-edit">
                            <use xlinkHref="#utouch-icon-edit"/>
                        </svg>
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        <table>
                            <tbody>
                                <tr>
                                    <td><input name="subscribe" type="checkbox" style={{ width: '16px' }} checked={this.state.viewModel.subscribe} onChange={this.handleChange} /></td>
                                    <td>Subscribe to our insights newsletter and receive market leading insights</td>
                                </tr>
                                <tr>
                                    <td><input name="terms" type="checkbox" checked={this.state.viewModel.terms} onChange={this.handleChange} /></td>
                                    <td>I agree to share my information with Nomad Digital Consulting and understand it will be used as described in our privacy policy.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button id="contact-submit" className="btn btn--green btn--with-shadow full-width contact-buttons">
                        Send a message
                   </button>
                    <div id="contact-validation" className="alert alert-danger contact-buttons" role="alert" style={{ display: 'none' }}>
                        <strong> Please first acknowledge the terms above</strong>
                    </div>
                    <div id="contact-submitting" className="alert alert-info contact-buttons" role="alert" style={{ display: 'none' }}>
                        <strong>Busy submitting...</strong>
                    </div>
                    <div id="contact-success" className="alert alert-success contact-buttons" role="alert" style={{ display: 'none' }}>
                        <strong>All done! Speak to you soon.</strong>
                    </div>
                    <div id="contact-failure" className="alert alert-danger contact-buttons" role="alert" style={{ display: 'none' }}>
                        <strong> Oops something went wrong...</strong>
                        <br/>Should the problem persist mail us direct at <a href="mailto:support@nomad.consulting">support@nomad.consulting</a>
                    </div>
                </form>
            </div>
        );
    }
}

const containerElement = document.getElementById('contact-app');
export function run(viewModel) {
    ReactDOM.render(
        <Contact viewModel={viewModel} />,
        containerElement);
}